import React from 'react'
import { navigate } from 'gatsby'
import Button from '../components/common/Button'

const Confirmation = ({ location }) => {
  const { version } = location.state || {}
  const contentDictionary = {
    waitList: {
      title: 'Thank you for your interest!',
      description: 'You have been added to the waitlist.',
      content:
        'It looks like your company has reached its allocated number of spots for Goodpath programs. We are working with your employer to open more spots. We will notify you as soon as you can begin your Goodpath program!',
      button: {
        text: 'Go to Homepage',
        to: '/'
      }
    }
  }

  const _content = contentDictionary[version]

  if (!_content && typeof window !== `undefined`) {
    navigate('/')
    return null
  }

  const { title, description, content, button } = _content || {}

  return (
    <div className="py-10 m-auto w-full bg-brand-pale-grey flex flex-col">
      <div className="w-full mx-auto bg-brand-pale-grey pb-10 flex flex-col">
        <h1 className="mx-auto md:text-4xl text-3xl mb-8 bold text-primary-base">
          {title}
        </h1>
        <h2 className="mx-auto mb-10 text-center md:text-3xl text-2xl bold">
          {description}
        </h2>
        <div className="md:w-2/4 w-full md:px-0 px-4 text-center mx-auto mb-6 md:mb-2 text-grey-dark md:text-lg text-md">
          <div className="pt-2 leading-normal">{content}</div>
        </div>
        {button && (
          <div className="md:mt-4 mt-6 w-full text-center mx-auto">
            <Button className="" onClick={navigate.bind(null, button.to)}>
              {button.text}
            </Button>
          </div>
        )}
        <button
          className="cursor-pointer text-primary-base block bold md:text-lg text-md my-6"
          onClick={navigate.bind(null, '/our-approach')}
        >
          Read about Our approach
        </button>
      </div>
    </div>
  )
}

export default Confirmation
